<template>
  <div class="dg-dd__wrapper">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DD-Layout",
  computed: {
    ...mapGetters({
      companyNumber: "getCompanyNo",
      companyData: "companyData",
    }),
  },
  mounted() {
    this.$store.commit("SET_COMPANY_NO", this.companyData.internal_no);
    this.$store.dispatch("loadInitialData", this.companyData.id);
    this.$store.dispatch("loadAuditArea");
    this.$store.dispatch("loadUserDocs", this.companyData.id);
  },
};
</script>

<style lang="scss" scoped>
.dg-dd__wrapper {
  width: 100%;
  overflow: auto;
  height: 100%;
  margin-top: 24px;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.54);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
  font-size: 16px;

  &.table {
    position: static;
    height: 300px;
  }
  img {
    margin-right: 6px;
  }
}
</style>
