<template>
  <div v-if="checkEmptyness">
    <div class="dd-certificate">
      <!--
      // FIXME
      <div class="certificate" v-for="(doc, k) in docs" :key="doc.attachment_id">
      <div class="certificate-title">
        <p>{{ $t(`documentation.${k}`) }}</p>
        <button @click="getDocument(docs[doc.attachment_id])" class="certificate-button">
          <img :src="getDownloadIcon()" />
        </button>
      </div>
    </div> -->
      <div class="certificate" v-if="'attachment' in docs['management_summary']">
        <div class="certificate-title">
          <p>{{ $t("documentation.management_summary") }}</p>
          <button @click="getDocument(docs['management_summary'].attachment_id)" class="certificate-button">
            <img :src="getDownloadIcon()" />
          </button>
        </div>
      </div>
      <div class="certificate" v-if="'attachment' in docs['registration_certificate']">
        <div class="certificate-title">
          <p>{{ $t("documentation.registration_certificate") }}</p>
          <button @click="getDocument(docs['registration_certificate'].attachment_id)" class="certificate-button">
            <img :src="getDownloadIcon()" />
          </button>
        </div>
      </div>
      <div class="certificate" v-if="'attachment' in docs['bafa_report']">
        <div class="certificate-title">
          <p>{{ $t("documentation.bafa") }}</p>
          <button @click="getDocument(docs['bafa_report'].attachment_id)" class="certificate-button">
            <img :src="getDownloadIcon()" />
          </button>
        </div>
      </div>
      <div class="certificate" v-if="'attachment' in docs['website_report']">
        <div class="certificate-title">
          <p>{{ $t("documentation.website_report") }}</p>
          <button @click="getDocument(docs['website_report'].attachment_id)" class="certificate-button">
            <img :src="getDownloadIcon()" />
          </button>
        </div>
      </div>
      <div class="certificate" v-if="'attachment' in docs['annual_report']">
        <div class="certificate-title">
          <p>{{ $t("documentation.annual_report") }}</p>
          <button @click="getDocument(docs['annual_report'].attachment_id)" class="certificate-button">
            <img :src="getDownloadIcon()" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DgCertificates",
  props: {
    docs: null,
  },
  computed: {
    checkEmptyness() {
      return (
        "attachment" in this.docs["management_summary"] ||
        "attachment" in this.docs["bafa_report"] ||
        "attachment" in this.docs["website_report"] ||
        "attachment" in this.docs["annual_report"] ||
        "attachment" in this.docs["registration_certificate"]
      );
    },
  },
  methods: {
    getDocument(documentId) {
      window.location.href = this.blueDownloadAttachmentLink(documentId);
    },
    getDownloadIcon() {
      return require(`../assets/download-${this.$i18n.locale}.svg`);
    },
  },
};
</script>
<style lang="scss" scoped>
.dd-certificate {
  display: flex;
  height: 135px;
  background-color: #ffffff;
  margin: 20px 0px;

  .certificate {
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;
    width: 280px;
    margin: 30px;
    border: 2px solid #dcdcdc;
    &:before {
      content: "";
      position: absolute;
      background-image: url(../assets/badge.svg);
      width: 20px;
      height: 28px;
      left: -10px;
      top: -10px;
    }
    img {
      margin: -20px;
    }
  }

  .certificate-title {
    margin-left: 16px;
    font-weight: bold;
    width: 100%;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 90%;
    }
  }

  .certificate-button {
    border: none;
    background: transparent;
    margin-left: 12px;

    img {
      margin-right: 4px;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
