<template>
  <div class="my-upload">
    <div class="my-upload__field">
      <div v-if="!getUploadsLoading">
        <EmptyUploads v-if="isDocsEmpty" @openModal="openModal"></EmptyUploads>
        <div class="my-upload__docs" v-else>
          <p class="my-upload__title" v-if="!isDocsEmpty">
            {{ $t("documentation.my_uploads") }}
            <DgButton class="upload-btn" @click="openModal">
              <span class="upload-btn__inner">
                <img src="../../assets/upload-24.svg" />
                <p>{{ $t("documentation.upload_document") }}</p>
              </span>
            </DgButton>
          </p>
          <UploadedDocs :docs="docs" />
        </div>
        <UploadForm :showModal="showModal" @closeModal="closeModal" @uploadStatus="uploadStatus" />
      </div>
      <div v-else class="loading"><img src="../../assets/ajax-loader.gif" />Please wait while we load the data...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Basic, Structural } from "@/dgui-customer-components/";
import UploadForm from "./UploadForm";
import UploadedDocs from "./UploadedDocs";
import EmptyUploads from "./EmptyUploads";

export default {
  name: "MyUpload",
  components: {
    DgButton: Basic.DgButton,
    UploadForm,
    EmptyUploads,
    UploadedDocs,
  },
  computed: {
    ...mapGetters(["companyData", "getUserDocs", "getUploadsLoading"]),
  },
  data() {
    return {
      showModal: false,
      files: [],
      isDocsEmpty: true,
      docs: [],
    };
  },
  watch: {
    getUserDocs(nVal, oVal) {
      if (nVal && "data" in nVal && nVal.data.length) {
        this.isDocsEmpty = false;
        this.docs = nVal.data.reverse();
      } else {
        this.isDocsEmpty = true;
      }
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    uploadStatus(status) {
      if (status === "uploaded") {
        this.closeModal();
        this.$store.dispatch("loadUserDocs", this.companyData.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my-upload {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  padding: 15px;
  background: #fff;
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  &__field__no-document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading {
    text-align: center;
  }
}
.upload-btn {
  margin-left: 20px;
}
.upload-btn__inner {
  display: flex;
}
.upload-btn__inner > p {
  margin: 0 10px;
}
.my-upload__docs {
  > p {
    padding-left: 16px;
  }
}
</style>
