<template>
  <DgModalCustom
    header="Upload Document"
    variant="large-right"
    btnsAlign="right"
    :showModal="showModal"
    @closeModal="closeModal"
    @cancelClick="closeModal"
    :confirmation="addOptions"
    @confirmClick="confirmClick"
    :confirmLoading="proceedLoading"
  >
    <form @submit.prevent="confirmClick" ref="cxUpload" enctype="multipart/form-data">
      <DgDragnDrop @filesDropped="droppedFiles" @invalidFileTypeError="invalidFileType" :maxFiles="10" :maxSize="5">
        <template v-slot:container>
          <div class="cxr-wrapper__dnd">
            <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31 19H1"
                stroke="#9AA7B0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 14V1"
                stroke="#9AA7B0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 10H26L31 19V29H1V19L6 10H10"
                stroke="#9AA7B0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 6L16 1L21 6"
                stroke="#9AA7B0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26 24H21"
                stroke="#9AA7B0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="cxr-wrapper__dnd">
              <div>
                <div class="cxr-wrapper__dnd-title">
                  {{ $t("documentation.upload_desc_one") }}
                </div>
                <div class="cxr-wrapper__dnd-desc">
                  {{ $t("documentation.upload_desc_two") }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </DgDragnDrop>
    </form>
  </DgModalCustom>
</template>
<script>
import { mapGetters } from "vuex";
import { Structural, Form } from "@/dgui-customer-components/";
export default {
  name: "UploadForm",
  data() {
    return {
      files: [],
      proceedLoading: false,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    addOptions() {
      return {
        title: "Add Document",
        cancel: this.$t("documentation.cancel"),
        confirm: this.$t("documentation.save"),
      };
    },
  },
  components: {
    DgModalCustom: Structural.DgModalCustom,
    DgDragnDrop: Form.DgDragnDrop,
  },
  methods: {
    async saveFiles() {
      this.proceedLoading = true;
      this.$emit("uploadStatus", "started");
      let formData = new FormData(this.$refs.cxUpload);
      formData.delete("file-input");
      this.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      await this.$http
        .post(`/post-form?url=/api1/v2/customers/${this.companyData.id}/customer_documents`, formData)
        .then(response => {
          this.proceedLoading = false;
        })
        .catch(error => {
          let errorMessage = {
            type: "Array",
            data: error.data,
          };
          this.proceedLoading = false;
          this.apiCatch(errorMessage);
        });
      this.$emit("uploadStatus", "uploaded");
    },
    closeModal() {
      this.$emit("closeModal");
    },
    confirmClick() {
      this.$emit("confirmClick");
      this.saveFiles();
    },
    droppedFiles(e) {
      this.files = e;
    },

    invalidFileType(e) {
      if (e) {
        this.error = true;
        this.errorText = "Please upload only PDF file formats";
      } else {
        this.error = false;
        this.errorText = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cxr-wrapper__dnd {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  font-size: 12px;
  color: $gray;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 18px;
  }

  .cxr-wrapper__dnd-title {
    color: $dark;
    font-size: 12px;
  }

  .cxr-wrapper__dnd-desc {
    line-height: 20px;
  }
}
</style>
