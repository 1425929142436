<template>
  <div>
    <p class="my-upload__title">{{ $t("documentation.my_uploads") }}</p>
    <div class="my-upload__field__no-document">
      <img src="../../assets/pdf-icon.svg" />
      <p>{{ $t("documentation.no_document") }}</p>
      <DgButton class="upload-btn" @click="proceedModal">
        <span class="upload-btn__inner">
          <img src="../../assets/upload-24.svg" />
          <p>{{ $t("documentation.upload_document") }}</p>
        </span>
      </DgButton>
    </div>
  </div>
</template>
<script>
import { Basic } from "@/dgui-customer-components/";
export default {
  name: "EmptyContent",
  components: {
    DgButton: Basic.DgButton,
  },
  methods: {
    proceedModal() {
      this.$emit("openModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.my-upload {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  padding: 15px;
  background: #fff;
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-left: 16px;
  }
  &__field__no-document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 34px;
  }
}
.upload-btn__inner {
  display: flex;
}
.upload-btn__inner > p {
  margin: 0 10px;
}
</style>
