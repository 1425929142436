<template>
  <div>
    <transition-group class="myd-docs" tag="ul" name="list">
      <li class="myd-doc" v-for="doc in docs" :key="doc.id">
        <div class="myd-doc__wrapper">
          <div class="myd-doc__thumbnail">
            <a href="#" @click.prevent="" v-tooltip="tooltipOptions(doc)" class="myd-doc__info-icon"></a>
            <img
              :src="`data:image/png;base64, ${doc.thumbnail}` || getSampleImage"
              @click.self="clickedItem(doc, 'open')"
              alt=""
            />
          </div>
          <div class="myd-doc__title">
            <div class="myd-doc__title__text">
              {{ doc.title }}
            </div>
            <div class="myd-doc__dropdown">
              <DgDropDown :title="dots" :id="doc.id" @clickedItem="clickedItem(doc, ...arguments)" :vals="options" />
            </div>
          </div>
        </div>
      </li>
    </transition-group>
    <DgModalCustom
      btnsAlign="right"
      :showModal="showDeleteModal"
      @closeModal="showDeleteModal = false"
      @cancelClick="showDeleteModal = false"
      :confirmation="deleteOptions"
      @confirmClick="confirmDeleteClick"
      :confirmLoading="deleteLoading"
    >
    </DgModalCustom>
    <DgModalCustom
      header="Rename document"
      :showModal="showRenameModal"
      @closeModal="closeRenameModal"
      @cancelClick="closeRenameModal"
      :confirmation="renameOptions"
      @confirmClick="confirmRenameClick"
      :confirmLoading="renameLoading"
    >
      <DgInput
        name="file_name"
        label="File Name"
        :value="selectedDoc.title"
        @input="fileName"
        placeholder="Filename"
      ></DgInput>
    </DgModalCustom>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import vTooltip from "v-tooltip";
import { Basic, Structural, Form } from "@/dgui-customer-components/";

Vue.use(vTooltip);

export default {
  name: "UploadedDocs",
  data() {
    return {
      selectedDoc: {},
      showDeleteModal: false,
      showRenameModal: false,
      renameLoading: false,
      deleteLoading: false,
      options: ["Open", "Rename", "Delete"],
      newFileName: null,
    };
  },
  props: {
    docs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    DgModalCustom: Structural.DgModalCustom,
    DgDropDown: Basic.DgDropDown,
    DgInput: Form.DgInput,
  },
  computed: {
    ...mapGetters(["getUserDocs", "companyData"]),
    getSampleImage() {
      return require("../../assets/sample.png");
    },
    deleteOptions() {
      return {
        title: "Delete document?",
        description: "This document will no longer be available on your documentation dashboard.",
        cancel: "No, keep document",
        confirm: "Yes, delete document",
      };
    },
    renameOptions() {
      return {
        title: "Rename document",
        cancel: "Cancel",
        confirm: "Save",
      };
    },
    dots() {
      return "&middot;&middot;&middot;";
    },
  },
  methods: {
    closeRenameModal() {
      this.showRenameModal = false;
      this.newFileName = null;
    },
    fileName(val) {
      this.newFileName = val;
    },
    async confirmRenameClick() {
      this.renameLoading = true;
      if (Boolean(this.newFileName) && this.newFileName !== this.selectedDoc.title) {
        let payload = {
          title: this.newFileName,
        };

        await this.$http
          .patch(`/api1/v2/customers/${this.companyData.id}/customer_documents/${this.selectedDoc.id}`, payload)
          .then(response => {
            this.renameLoading = false;
            this.$store.dispatch("loadUserDocs", this.companyData.id);
            this.showRenameModal = false;
          })
          .catch(error => {
            this.renameLoading = false;
            this.showRenameModal = false;
            this.apiCatch(error);
          });
      }
    },
    async confirmDeleteClick() {
      this.deleteLoading = true;
      await this.$http
        .delete(`/api1/v2/customers/${this.companyData.id}/customer_documents/${this.selectedDoc.id}`)
        .then(response => {
          this.deleteLoading = false;
          this.$store.dispatch("loadUserDocs", this.companyData.id);
          this.showDeleteModal = false;
        })
        .catch(error => {
          this.deleteLoading = false;
          this.showDeleteModal = false;
          this.apiCatch(error);
        });
    },
    clickedItem(doc, val) {
      this.selectedDoc = doc;
      switch (val.toLowerCase()) {
        case "open":
          this.getDocument();
          break;
        case "delete":
          this.showDeleteModal = true;
          break;
        case "rename":
          this.showRenameModal = true;
          break;
        default:
          break;
      }
    },
    getDate(d) {
      return d ? new Date(d).toLocaleString("de-DE") : d;
    },
    tooltipOptions(doc) {
      return {
        html: true,
        content: `<div class="dd-tooltip">
                    <div class="dd-tooltip__title">Details</div>
                    <ul>
                      <li>
                        <div class="dd-tooltip__label">File Type</div>
                        <div class="dd-tooltip__val">application/pdf</div>
                      </li>
                      <li>
                        <div class="dd-tooltip__label">Size</div>
                        <div class="dd-tooltip__val">${doc.size}</div>
                      </li>
                      <li>
                        <div class="dd-tooltip__label">Created</div>
                        <div class="dd-tooltip__val">${this.getDate(doc.created_at)}</div>
                      </li>
                    </ul>
                  </div>`,
        placement: "bottom-center",
        classes: ["dd-info"],
        loadingClass: ["dd-info-tooltip"],
      };
    },
    getDocument() {
      let url = `/api1/v2/customers/${this.companyData.id}/customer_documents/${this.selectedDoc.id}`;
      this.$http
        .get(url, {
          responseType: "arraybuffer",
        })
        .then(response => {
          var blob = new Blob([response.data], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.selectedDoc.title;
          link.click();
        })
        .catch(error => {
          this.apiCatch(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.myd-docs {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0 0 65px 0;
  justify-content: flex-start;
  max-height: 500px;
  overflow: auto;
  min-height: 236px;

  > .myd-doc {
    display: flex;
    align-content: flex-start;
    width: 25%;
    padding: 16px;
    height: 236px;
    > .myd-doc__wrapper {
      width: 100%;
      height: 100%;
      border: 1px solid #dfe4e9;
      position: relative;

      .myd-doc__info-icon {
        position: absolute;
        top: 6px;
        right: 6px;
        background-image: url(../../assets/icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 22px;
        height: 22px;
        &.v-tooltip-open {
          background-image: url(../../assets/icon-active.svg);
        }
      }

      .myd-doc__thumbnail {
        cursor: pointer;
        height: 144px;
        padding: 12px 12px 0 12px;
        background: #f2f4f6;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .myd-doc__title {
        min-height: 72px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #003349;
        position: relative;

        &__text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 16px;
        }
        padding-right: 50px;

        .myd-doc__dropdown {
          position: absolute;
          right: 0;
          top: 6px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tooltip {
  &.dd-info {
    $color: rgba(#003349, 0.9);

    .tooltip-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
      width: 280px;
    }

    .dd-tooltip {
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      .dd-tooltip__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          display: flex;
          padding-right: 16px;
          .dd-tooltip__label {
            width: 40%;
          }
          .dd-tooltip__val {
            font-weight: bold;
            width: 60%;
            white-space: wrap;
            word-break: break-all;
          }
        }
      }
    }

    .tooltip-arrow {
      border-color: $color;
    }
  }
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>
