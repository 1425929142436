<template>
  <div v-if="docs[docType][auditKey].length" class="documentation-content_multiple">
    <span
      @click="getDocument(document.attachment.id)"
      class="pdf active"
      v-for="(document, i) in docs[docType][auditKey]"
      :key="i"
    >
      <i class="icon"></i>
      <span class="title">{{ getDocTitle(document) }}</span>
    </span>
  </div>
  <span class="pdf" v-else>
    <i class="icon"></i>
    <span class="title">{{ $t("documentation.not_available") }}</span>
  </span>
</template>
<script>
export default {
  name: "DgTableCell",
  props: {
    docs: {
      type: Object,
      required: true,
    },
    auditKey: {
      type: String,
      required: true,
    },
    docType: {
      type: String,
      required: true,
    },
    extraDocs: {
      type: Array,
      required: true,
    },
    preTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    getDocument(documentId) {
      window.location.href = this.blueDownloadAttachmentLink(documentId);
    },
    getDocTitle(audit) {
      let created_at = this.formatDate(new Date(audit.attachment.created_at), "dd.MM.yyyy");
      let fileName = audit.attachment.name && audit.attachment.name.toLowerCase();
      let docType = this.extraDocs.find(auditArea => fileName.includes(auditArea));
      let docTitle = `${this.preTitle} ${created_at}`;
      if (docType === "_tom") {
        docTitle = `TOMS ${created_at}`;
      }
      return docTitle || audit.attachment.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  // border-bottom: 1px solid #d7dbdd;
  opacity: 0.1;
  padding: 13px 8px;
  margin: 0 auto !important;
  border-bottom: 2px solid #fff;

  &:hover {
    background: #fff;
  }

  &:last-child {
    border-bottom: none;
  }

  .icon {
    margin: auto;
    width: 46px;
    height: 46px;
    background-image: url("../assets/pdf.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }
  &.active {
    opacity: 1;
    cursor: pointer;
  }
  .title {
    font-size: 12px;
    text-align: center;
    max-width: 100%;
  }
}
.pdf-double {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  border-bottom: 1px solid #d7dbdd;
  opacity: 0.1;

  .icon-it {
    display: block;
    margin: 0 auto;
    width: 23px;
    height: 23px;
    background-image: url("../assets/pdf.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }
  &.active {
    opacity: 1;
  }
  .title-it {
    font-size: 10px;
    margin-top: 5px;
    text-align: center;
  }
}

.documentation-content_multiple {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  height: 100%;

  & > .pdf {
    width: 100%;
    margin-top: 10px;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .icon {
      background-size: contain;
      width: 32px;
      height: 26px;
      margin: 0;
    }
    .title {
      display: inline;
      margin-top: 0;
      padding-left: 2px;
    }
  }
}
</style>
