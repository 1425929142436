<template>
  <div v-if="companyData">
    <div class="container-fluid" v-if="isUserAllowed">
      <div class="row align-items-center justify-content-center">
        <div class="col col-10">
          <DgDpoIntro
            class="dpo-intro__modified"
            :dpo="companyData.main_contact_dpo"
            :title="$t('documentation.title')"
          >
            <template v-slot:desc>
              <p>{{ $t("documentation.heading") }}</p>
              <span>- {{ companyData.main_contact_dpo.first_name }} {{ companyData.main_contact_dpo.last_name }}</span>
            </template>
            <a
              v-if="'attachment' in certificates.designation_document"
              href="#"
              @click.prevent="getDocument(certificates.designation_document.attachment_id)"
              class="dd-doc"
            >
              <img src="../assets/benennungsurkunde.svg" alt="" />
            </a>
          </DgDpoIntro>
          <DgCertificates :docs="certificates" />
          <MyUploads />
          <div class="mrT documentation-table-wrapper">
            <table v-if="auditAreaData" class="documentation-table">
              <thead>
                <tr class="documentation-headings">
                  <th>{{ $t("documentation.document_type") }}</th>
                  <th v-for="(auditArea, index) in docs" :key="index">
                    <span>{{ $t(`documentation.${index}`) }} </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="documentation-content" v-for="(auditValue, auditKey, count) in getAuditKeys" :key="count">
                  <td class="audit-type">
                    <img :src="getImage(getAuditTitles[count].audit_area.title_de)" style="fill: white" />
                    {{ getTitle(getAuditTitles[count].audit_area) }}
                  </td>
                  <td v-for="(docType, index) in docs" :key="index">
                    <DgTableCell
                      :pre-title="`${$t(`documentation.cell_titles.${index}`)} ${getTitle(
                        getAuditTitles[count].audit_area
                      )}`"
                      :extraDocs="extra_docs"
                      :docs="docs"
                      :docType="index"
                      :auditKey="auditKey"
                    />
                  </td>
                </tr>
                <tr v-if="!auditAreaData.length">
                  <td class="no-data">
                    <template> No Documents Found </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="loading table">
              <img src="../assets/ajax-loader.gif" />Please wait while we load the data...
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <div class="restricted">
        {{ $t("documentation.restricted1") }}
        <a href="" @click.prevent="back">{{ $t("documentation.link") }}</a>
        {{ $t("documentation.restricted2") }}
      </div>
    </div>
  </div>
</template>

<script>
import { Structural } from "@/dgui-customer-components/";
import { mapGetters } from "vuex";
import { i18n } from "@/translations";
import DgCertificates from "../components/DgCertificates";
import DgTableCell from "../components/DgTableCell";
import MyUploads from "../components/MyUploads/MyUploads";

export default {
  name: "DD-Dashboard",
  components: {
    DgDpoIntro: Structural.DgDpointro,
    DgCertificates,
    DgTableCell,
    MyUploads,
  },
  data() {
    return {
      auditAreaData: null,
      docs: {
        protocol: { k: [], e: [], v: [], f: [], p: [], i: [] },
        vvt: { k: [], e: [], v: [], f: [], p: [], i: [] },
        guidances: { k: [], e: [], v: [], f: [], p: [], i: [] },
      },
      extra_docs: [
        "protocol",
        "vvt",
        "guidances",
        "protokoll",
        "management_summary",
        "_bafa",
        "benennungsurkunde",
        "designation_document",
        "_tom",
        "_vvt_it",
        "website_report",
        "jahresbericht",
        "websitereport",
        "annual_report",
        "registration_certificate",
      ],
      certificates: {
        management_summary: {},
        designation_document: {},
        bafa_report: {},
        website_report: {},
        annual_report: {},
        registration_certificate: {},
      },
    };
  },
  computed: {
    isUserAllowed() {
      return (
        this.companyData.is_current_user_main_contact ||
        this.companyData.role === "senior_dpo" ||
        this.companyData.role === "dpo" ||
        this.companyData.role === "sales"
      );
    },
    ...mapGetters({
      customerActivity: "getCustomerActivity",
      allAuditAreas: "getAuditArea",
      companyData: "companyData",
    }),
    getAuditKeys() {
      return this.getAllAuditAreas.reduce((acc, val) => {
        acc[val.title_de.charAt(0).toLowerCase()] = {};
        return acc;
      }, {});
    },
    getAllAuditAreas() {
      return this.allAuditAreas
        ? this.allAuditAreas.filter(el => {
            return el.variable_name !== "core-process-3" && el.variable_name !== "core-process-2";
          })
        : [];
    },
    getAuditTitles() {
      let auditTitles = this.getAllAuditAreas.map(a => {
        let area = this.auditAreaData.find(auditArea => {
          return auditArea.audit_area.title === a.title;
        });
        return area || { audit_area_id: a.id, audit_area: a };
      });
      return auditTitles;
    },
  },
  watch: {
    customerActivity(n, o) {
      this.auditAreaData = n
        .filter(el => el.assigns.some(elx => elx.publish))
        .map(el => {
          el.assigns = el.assigns.find(elx => elx.publish);
          return el;
        });
      this.auditAreaData.forEach(el => {
        this.createDocs(el);
      });
    },
  },
  methods: {
    getDocument(documentId) {
      window.location.href = this.blueDownloadAttachmentLink(documentId);
    },
    getDownloadIcon() {
      return require(`../assets/download-${this.$i18n.locale}.svg`);
    },
    back() {
      this.$router.go(-1);
    },
    createDocs(el) {
      let fileName = el.assigns.attachment.name && el.assigns.attachment.name.toLowerCase();
      let docType = this.extra_docs.find(auditArea => fileName.includes(auditArea));
      if (docType) {
        switch (docType) {
          case "protocol":
            if (/_[kvefpi]+_audit_protocol/.test(fileName)) {
              const protocol = fileName.split("_audit_protocol")[0].split("_");
              protocol[protocol.length - 1].split("").forEach(col => {
                this.docs.protocol[col].push(el.assigns);
              });
            }
            if (/_[kvefpi]+_protocol/.test(fileName)) {
              const protocol = fileName.split("_protocol")[0].split("_");
              protocol[protocol.length - 1].split("").forEach(col => {
                this.docs.protocol[col].push(el.assigns);
              });
            }
            break;
          case "protokoll":
            if (/_[kvefpi]+_audit_protokol/.test(fileName)) {
              const protokoll = fileName.split("_audit_protokol")[0].split("_");
              protokoll[protokoll.length - 1].split("").forEach(col => {
                this.docs.protocol[col].push(el.assigns);
              });
            }
            if (/_[kvefpi]+_protokol/.test(fileName)) {
              const protokoll = fileName.split("_protokol")[0].split("_");
              protokoll[protokoll.length - 1].split("").forEach(col => {
                this.docs.protocol[col].push(el.assigns);
              });
            }
            break;
          case "vvt":
            if (/_[kvefpi]+_processing_activities_vvt/.test(fileName)) {
              const vvt = fileName.split("_processing_activities_vvt")[0].split("_");
              vvt[vvt.length - 1].split("").forEach(col => {
                this.docs.vvt[col].push(el.assigns);
              });
            }
            if (/_[kvefpi]+_vvt/.test(fileName)) {
              const vvt = fileName.split("_vvt")[0].split("_");
              vvt[vvt.length - 1].split("").forEach(col => {
                this.docs.vvt[col].push(el.assigns);
              });
            }
            break;
          case "guidances":
            if (/_[kvefpi]+_guidances/.test(fileName)) {
              const guidances = fileName.split("_guidance")[0].split("_");
              guidances[guidances.length - 1].split("").forEach(col => {
                this.docs.guidances[col].push(el.assigns);
              });
            }
            break;
          case "management_summary":
            this.certificates.management_summary = el.assigns;
            break;
          case "registration_certificate":
            this.certificates.registration_certificate = el.assigns;
            break;
          case "_bafa":
            this.certificates.bafa_report = el.assigns;
            break;
          case "designation_document":
          case "benennungsurkunde":
            this.certificates.designation_document = el.assigns;
            break;
          case "_tom":
            this.docs.guidances.i.push(el.assigns);
            break;
          case "_vvt_it":
            this.docs.vvt.i.push(el.assigns);
            break;
          case "website_report":
          case "websitereport":
            this.certificates.website_report = el.assigns;
            break;
          case "annual_report":
          case "jahresbericht":
            this.certificates.annual_report = el.assigns;
            break;
          default:
            break;
        }
      }
    },
    getImage(title) {
      const img = title.toLowerCase().includes("kernprozess") ? "Kernprozess" : title;
      return require(`../assets/audit_area_icons/${img}.svg`);
    },
    getTitle(auditArea) {
      return auditArea[`title_${i18n.locale}`] || auditArea["title"];
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 60px;
}
.documentation-table {
  position: relative;
  width: 100%;
  table-layout: fixed;
  border: 1px solid #dfe4e9;

  .documentation-headings {
    height: 60px;

    th {
      color: #ffffff;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      background: #254a5d;
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        width: 200px;
      }

      &:nth-child(3) {
        border-right: none;
      }
    }
  }

  .documentation-content {
    border-bottom: 2px solid #dfdfe0;

    .audit-type {
      color: #ffffff;
      background: #0b344b;
      padding: 0 20px 0 20px;
      border-right: 2px solid #ffffff;
      border-left: none;
      height: 120px;
      border-bottom: 2px solid #ffffff;
      font-size: 16px;
      font-weight: bold;
    }

    td {
      background: #f1f1f1;
      width: 120px;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      height: 1px;
    }

    &:nth-child(1) {
      .audit-type {
        border-left: none;
        border-right: none;
        background-color: #cb333b;
      }
    }

    &:nth-child(2) {
      .audit-type {
        border: none;
        background-color: #11212a;
      }
    }

    &:nth-child(3) {
      .audit-type {
        border: none;
        background-color: #002e42;
      }
    }

    &:nth-child(4) {
      .audit-type {
        border: none;
        background-color: #214354;
      }
    }

    &:nth-child(5) {
      .audit-type {
        border: none;
        background-color: #162c38;
      }
    }
  }
}

.dd-doc {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  img {
    height: 100%;
  }
}

.restricted {
  margin-top: 32px;
  padding: 16px;
  background: #ffffff;
}

.no-data {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.documentation-table-wrapper {
  background: #fff;
  padding: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 60px;
}

.dpo-intro__modified {
  padding-right: 275px;
}
</style>
